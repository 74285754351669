/* Hide the default arrows */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.scrollable {
  overflow-y: scroll;
  /* max-height: 950px; */
  background: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

form button {
  background: var(--custom-btn-bg-hover-color);
  border: none;
  color: var(--white-color);
}

.translate-y-50 {
  transform: translateY(-50%);
}
.text-primary {
  color: var(--primary-color) !important;
}
.btn-primary {
  background: var(--primary-color) !important;
  color: white;
  border: none;
}
.btn-primary:hover {
  background-color: #14542b !important;
}

td > small {
  color: black !important;
}

.avatar.avatar-xl {
  border-radius: 50%;
  background-color: #ececec;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar.avatar-sm {
  border-radius: 50%;
  background-color: #ececec;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: small;
}

.avatar.avatar-2xl {
  border-radius: 50%;
  background-color: #ececec;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
}

.pt-8 {
  padding-top: 8rem !important;
}

.pt-md-10 {
  @media (min-width: 768px) {
    padding-top: 10rem !important;
  }
}

.pt-lg-12 {
  @media (min-width: 992px) {
    padding-top: 12rem !important;
  }
}

/* Notification channels. */
@media (max-width: 576px) {
  .channel-value {
    font-size: 0.5rem;
    margin-right: 2rem;
  }

  .icon-small {
    width: 0.7rem;
    height: 0.7rem;
  }
}

.text-custom {
  color: #b2e1d0;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.account-table {
  width: 100%;
  /* min-width: 800px; Adjust this value based on your table's minimum width requirements */
}

.carousel-indicators .active {
  background-color: var(--primary-color) !important;
}

.overflow-x-scroll {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-scrolling: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: hidden;
}

.side-bar-transition {
  transition: all 0.5s ease;
}

.role-card {
  border-radius: 10px;
  padding: 10px;
}

.role-card:hover {
  background-color: #f1f1f1;
}

.btn-lift {
  transition:
    transform 0.2s ease,
    box-shadow 0.3s ease;
}

.btn-lift:hover {
  transform: translateY(-4px); /* Move the button up */
}

/* cyrillic-ext */
@font-face {
  font-family: '__Unbounded_828d8f';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/e546cf32c9d4702c-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Unbounded_828d8f';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/d76fe899475ae4d7-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Unbounded_828d8f';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/8048a25e80cc4179-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Unbounded_828d8f';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/84e2ca9032588f29-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Unbounded_828d8f';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/176b159565394c49-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Unbounded_828d8f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e546cf32c9d4702c-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Unbounded_828d8f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d76fe899475ae4d7-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Unbounded_828d8f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8048a25e80cc4179-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Unbounded_828d8f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/84e2ca9032588f29-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Unbounded_828d8f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/176b159565394c49-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Unbounded_828d8f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e546cf32c9d4702c-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Unbounded_828d8f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d76fe899475ae4d7-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Unbounded_828d8f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/8048a25e80cc4179-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Unbounded_828d8f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/84e2ca9032588f29-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Unbounded_828d8f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/176b159565394c49-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Unbounded_Fallback_828d8f';src: local("Arial");ascent-override: 73.93%;descent-override: 18.20%;line-gap-override: 0.00%;size-adjust: 134.59%
}.__className_828d8f {font-family: '__Unbounded_828d8f', '__Unbounded_Fallback_828d8f';font-style: normal
}

