/* Hide the default arrows */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.scrollable {
  overflow-y: scroll;
  /* max-height: 950px; */
  background: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

form button {
  background: var(--custom-btn-bg-hover-color);
  border: none;
  color: var(--white-color);
}

.translate-y-50 {
  transform: translateY(-50%);
}
.text-primary {
  color: var(--primary-color) !important;
}
.btn-primary {
  background: var(--primary-color) !important;
  color: white;
  border: none;
}
.btn-primary:hover {
  background-color: #14542b !important;
}

td > small {
  color: black !important;
}

.avatar.avatar-xl {
  border-radius: 50%;
  background-color: #ececec;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar.avatar-sm {
  border-radius: 50%;
  background-color: #ececec;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: small;
}

.avatar.avatar-2xl {
  border-radius: 50%;
  background-color: #ececec;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
}

.pt-8 {
  padding-top: 8rem !important;
}

.pt-md-10 {
  @media (min-width: 768px) {
    padding-top: 10rem !important;
  }
}

.pt-lg-12 {
  @media (min-width: 992px) {
    padding-top: 12rem !important;
  }
}

/* Notification channels. */
@media (max-width: 576px) {
  .channel-value {
    font-size: 0.5rem;
    margin-right: 2rem;
  }

  .icon-small {
    width: 0.7rem;
    height: 0.7rem;
  }
}

.text-custom {
  color: #b2e1d0;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.account-table {
  width: 100%;
  /* min-width: 800px; Adjust this value based on your table's minimum width requirements */
}

.carousel-indicators .active {
  background-color: var(--primary-color) !important;
}

.overflow-x-scroll {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-scrolling: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: hidden;
}

.side-bar-transition {
  transition: all 0.5s ease;
}

.role-card {
  border-radius: 10px;
  padding: 10px;
}

.role-card:hover {
  background-color: #f1f1f1;
}

.btn-lift {
  transition:
    transform 0.2s ease,
    box-shadow 0.3s ease;
}

.btn-lift:hover {
  transform: translateY(-4px); /* Move the button up */
}
